<script lang="ts">
  import classnames from 'classnames';
  // Utils
  import { omit } from '$lib/utils/object';
  import type { CourseModel, SessionModel } from '@24hbs/utils';
  import type { CourseLightModel, SessionLightModel } from '@24hbs/utils';
  // Components
  import { CourseCard } from '$lib/components/organisms/Card';
  import { SectionCarouselSlides } from '$lib/components/organisms/SectionCarousel';

  export let courses: (
    | CourseModel
    | CourseLightModel
    | SessionModel
    | SessionLightModel
  )[];
  export let gtmEventActive: boolean = false;
  export let gtmList: string | null = null;
  export let lazyLoad: boolean = false;

  export let noHorizontalPadding = false;
  export let desktopColumns: 'two' | 'three' = 'three';
  export let sectionCarouselId: string = '';

  const attributes = omit($$restProps, 'class');
</script>

{#if courses && courses.length && courses.length > 0}
  <div
    class={classnames('card-container', $$props.class, {
      'v-no-h-padding': noHorizontalPadding,
      'v-2-cols': desktopColumns === 'two',
      'v-3-cols': desktopColumns === 'three',
    })}
    {...attributes}
  >
    {#if sectionCarouselId !== ''}
      <SectionCarouselSlides id={sectionCarouselId}>
        {#each courses as course, index}
          <CourseCard lazyLoad={lazyLoad} useSessionLink {course} positionInPage={index + 1} />
        {/each}
      </SectionCarouselSlides>
    {:else}
      {#each courses as course, index}
        <CourseCard lazyLoad={lazyLoad}  positionInPage={index + 1} useSessionLink {course} />
      {/each}
    {/if}
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .card-container {
    --card-max-width: 350px;
    --card-width: 80vw;

    @apply page-max-width;
    @apply py-3;
    @apply flex flex-nowrap overflow-x-auto overflow-y-hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-destination: 100% 0%;
    /* You can ommit it in iOS 13 */
    -webkit-overflow-scrolling: touch;
    scroll-padding: var(--page-margin);
    padding-left: var(--page-margin);

    /* From scrollable area to grid items */
    @apply lg:grid lg:gap-grid-default lg:page-margin lg:page-max-width;

    &::after {
      content: '';
      padding-left: var(--page-margin);
      @apply block;
      @apply lg:hidden;
    }

    &.v-2-cols {
      @apply lg:grid-cols-2;
    }

    &.v-3-cols {
      @apply lg:grid-cols-3;
    }

    &.v-no-h-padding {
      @apply px-0;
    }
  }

  :global(body .card-container > *) {
    @apply flex-shrink-0 lg:flex-shrink;
    width: var(--card-width) !important;
    max-width: var(--card-max-width) !important;
    scroll-snap-align: start;

    @screen lg {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  :global(body .card-container > [data-forward] > *) {
    @apply flex-shrink-0 lg:flex-shrink;
    width: var(--card-width) !important;
    max-width: var(--card-max-width) !important;
    scroll-snap-align: start;

    @screen lg {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  :global(.card-container > * + *) {
    margin-left: var(--page-grid-gap);
    @apply lg:ml-0;
  }

  :global(.card-container > [data-forward] > * + *) {
    margin-left: var(--page-grid-gap);
    @apply lg:ml-0;
  }

  .card-container::-webkit-scrollbar {
    @apply hidden;
  }
</style>
